import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../../@core/utils';

@Component({
  selector: 'ngx-policys',
  templateUrl: './policys.component.html',
  styleUrls: ['./policys.component.scss']
})
export class PolicysComponent implements OnInit {

  constructor(
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.titleService.currentTitle = "Privacy Policy";
    this.titleService.currentSubTitle = null;
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectsApi } from '../api/projects.api';
import { Project, ProjectData } from '../../../interfaces/common/project';

@Injectable()
export class ProjectsService extends ProjectData {

  constructor(private api: ProjectsApi) {
    super();
  }

  getAll(): Observable<Project[]> {
    return this.api.getAll();
  }

  // just to mock data
  getCurrentProject(): Observable<Project> {
    return this.api.getAll();
  }

  get(id: number): Observable<any> {
    return this.api.get(id);
  }

  update(project: any): Observable<Project> {
    return this.api.update(project);
  }
  
  updateCurrent(project: any): Observable<Project> {
    return this.api.updateCurrent(project);
  }
  
  // delete(id: number): Observable<boolean> {
  //   return this.api.delete(id);
  // }
  
  // create(user: any): Observable<User> {
  //   return this.api.add(user);
  // }
  }

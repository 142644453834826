<nb-card>
        <nb-card-header>
          <h3 class="text-primary px-lg-3">Privacy Policy</h3>
          <section class="another-action" aria-label="Register">
                Return page <a class="text-link" routerLink="/auth/register">Register</a>
          </section>
        </nb-card-header>
        <nb-card-body class="px-md-3 px-lg-5">
          <p><strong>Introduction</strong></p>
          <p>
            <strong>Artistic Visions LLC</strong
            ><span style="font-weight: 400"
              >, a Florida limited liability company (</span
            ><strong>"Company"</strong><span style="font-weight: 400"> or</span
            ><strong>&nbsp;"We"</strong
            ><span style="font-weight: 400"
              >) respects your privacy and is committed to protecting it through our
              compliance with this policy.</span
            >
          </p>
          <p>
            <span style="font-weight: 400"
              >This policy describes the types of information we may collect from you
              or that you may provide when you visit the website at </span
            ><a href="https://www.artisticvisions.com/"
              ><span style="font-weight: 400">www.artisticvisions.com</span></a
            ><span style="font-weight: 400"> and </span
            ><a href="http://www.avwaveinteractive.com/"
              ><span style="font-weight: 400">www.avwaveinteractive.com</span></a
            ><span style="font-weight: 400"> (collectively, the &ldquo;</span
            ><strong>Website</strong
            ><span style="font-weight: 400"
              >&rdquo;) or the web application at www.avwaveinteractive.com (the
              &ldquo;</span
            ><strong>App</strong
            ><span style="font-weight: 400"
              >&rdquo;; together, with the Website, our &ldquo;</span
            ><strong>Site</strong
            ><span style="font-weight: 400"
              >&rdquo;) and our practices for collecting, using, maintaining,
              protecting, and disclosing that information.</span
            >
          </p>
          <p>
            <span style="font-weight: 400"
              >This policy applies to information we collect:</span
            >
          </p>
          <ul>
            <li style="font-weight: 400">
              <span style="font-weight: 400">On this Site.</span>
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400">On the Application.</span>
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >In email, text, and other electronic messages between you and the
                Site or the Company.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400">Through our licensees (the &ldquo;</span
              ><strong>Licensees</strong
              ><span style="font-weight: 400"
                >&rdquo;), who are users of the Site, with whom you contract to
                receive the benefits of the Site.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >When you interact with our advertising, applications, or links on
                third-party websites and services, if those applications or
                advertising include links to this policy.</span
              >
            </li>
          </ul>
          <p>
            <span style="font-weight: 400"
              >It does not apply to information collected by:</span
            >
          </p>
          <ul>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >Us offline or through any other means, including on any other website
                operated by Company or any third party; or&nbsp;</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >Our Licensees, with whom you contracted to receive the benefits of
                the Site; information collected by our Licensees and held by our
                Licensees is subject to their own privacy policy.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >Any third party, including through any application or content
                (including advertising) that may link to or be accessible from or on
                the Site.</span
              >
            </li>
          </ul>
          <p>
            <span style="font-weight: 400"
              >Please read this policy carefully to understand our policies and
              practices regarding your information and how we will treat it. If you do
              not agree with our policies and practices, then your choice is not to
              use our Site. By accessing or using this Site, you agree to this privacy
              policy. This policy may change from time to time (see Your Canada Data
              Protection Rights). Your continued use of this Site after we make
              changes is deemed to be acceptance of those changes, so please check the
              policy periodically for updates.&nbsp;</span
            >
          </p>
          <p><strong>Children Under the Age of 16</strong></p>
          <p>
            <span style="font-weight: 400"
              >Our Site is not intended for children under 16 years of age. No one
              under age 16 may provide any information to or on the Site. We do not
              knowingly collect personal information from children under 16. If you
              are under 16, do not use or provide any information on this Site or
              through any of its features, register on the Site, make any purchases
              through the Site, use any of the interactive or public comment features
              of this Site, or provide any information about yourself to us, including
              your name, address, telephone number, email address, or any screen name
              or user name you may use. If we learn we have collected or received
              personal information from a child under 16 without the proper and
              necessary consent, we will delete that information. If you believe we
              might have any information from or about a child under 16, please
              contact us at
              <a href="mailto:support@avwaveinteractive.com"
                >support@avwaveinteractive.com</a
              >.</span
            >
          </p>
          <p>
            <strong>Information We Collect About You and How We Collect It</strong>
          </p>
          <p>
            <span style="font-weight: 400"
              >We collect several types of information from and about users of our
              Site, including information:</span
            >
          </p>
          <ul>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >By which you may be personally identified, such as name, postal
                address, e-mail address, telephone number, or any other identifier by
                which you may be contacted online or offline ("</span
              ><strong>personal information</strong
              ><span style="font-weight: 400">");</span>
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >That is about you but individually does not identify you, such as
                gender, birthdate, or other information about you but does not
                identify you; and/or</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >About your internet connection, the equipment you use to access our
                Site, and usage details.</span
              >
            </li>
          </ul>
          <p><span style="font-weight: 400">We collect this information:</span></p>
          <ul>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >Directly from you when you provide it to us.Automatically as you
                navigate through the site. Information collected automatically may
                include usage details, IP addresses, and information collected through
                cookies, web beacons, and other tracking technologies.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >From third parties, for example, our business partners.</span
              >
            </li>
          </ul>
          <p>
            <em
              ><span style="font-weight: 400">Information You Provide to Us</span></em
            ><strong>&nbsp;&nbsp;</strong>
          </p>
          <p>
            <span style="font-weight: 400"
              >The information we collect on or through our Site may include:</span
            >
          </p>
          <ul>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >Information that you provide by filling in forms on our Site. This
                includes information provided at the time of registering to use our
                Site, subscribing to our service, posting material, or requesting
                further services. We may also ask you for information when you report
                a problem with our Site.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >Records and copies of your correspondence (including email
                addresses), if you contact us.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >Information that you provide to our Licensees, with whom you have
                contracted to receive the benefits of the Site, which we need to know
                in order to allow you to use the Site and receive such benefits.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >Your responses to surveys that we might ask you to complete for
                research purposes.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >Details of transactions you carry out through our Site and of the
                fulfillment of your orders. You may be required to provide financial
                information before placing an order through our Site.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400">Your search queries on the Site.</span>
            </li>
          </ul>
          <p>
            <em
              ><span style="font-weight: 400"
                >Information We Collect from Other Sources</span
              ></em
            ><span style="font-weight: 400"
              >. We may collect information about you from others, such
              as:&nbsp;</span
            >
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>
              <strong
                ><strong>Third-Party Sources</strong
                ><span style="font-weight: 400"
                  >. Examples of third-party sources include marketers, partners,
                  researchers, users of our Site and Licensees, affiliates (like
                  companies connected to the Site), and others where they are legally
                  allowed to share your information with us. For example, if you
                  register for our Services on another Site, or on a Licensee&rsquo;s
                  website, the website may provide your information to us.</span
                ></strong
              >
            </li>
          </ul>
          <ul>
            <li>
              <strong>Other Customers</strong
              ><span style="font-weight: 400"
                >. Other customers may give us your information.&nbsp;</span
              >
            </li>
          </ul>
          <ul>
            <li>
              <strong>Combining Information from Different Sources</strong
              ><span style="font-weight: 400"
                >. We may combine the information we receive from other sources with
                information we collect from you (or your device) and use it as
                described in this Privacy Policy.</span
              >
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>
            <em
              ><span style="font-weight: 400"
                >Information We Collect &amp; Process on Behalf of Customers</span
              ></em
            ><span style="font-weight: 400"
              >. When our customers use our Services, we process and store certain
              information on their behalf as a data processor. For example, when a
              Licensee (or the Licensee&rsquo;s users) upload files or documents, we
              act primarily as a data processor and process information on the
              Licensee&rsquo;s behalf and in accordance with their instructions. In
              those instances, the Licensee as the data controller is responsible for
              most aspects of the processing of the information. If you have any
              questions or concerns about how information is processed in these cases,
              including how to exercise your rights as a data subject, we recommend
              contacting the relevant Licensee.</span
            >
          </p>
          <p>
            <em
              ><span style="font-weight: 400"
                >Information We Collect Through Automatic Data Collection
                Technologies</span
              ></em
            ><strong><em>&nbsp;&nbsp;</em></strong>
          </p>
          <p>
            <span style="font-weight: 400"
              >As you navigate through and interact with our Site, we may use
              automatic data collection technologies to collect certain information
              about your equipment, browsing actions, and patterns, including:</span
            >
          </p>
          <ul>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >Details of your visits to our Site, including traffic data, location
                data, logs, and other communication data and the resources that you
                access and use on the Site.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >Information about your computer and internet connection, including
                your IP address, operating system, and browser type.</span
              >
            </li>
          </ul>
          <p>
            <span style="font-weight: 400"
              >We also may use these technologies to collect information about your
              online activities over time and across third-party websites or other
              online services (behavioral tracking). You can opt out of behavioral
              tracking on this Site by refusing to use the Site.</span
            >
          </p>
          <p>
            <span style="font-weight: 400"
              >The information we collect automatically may include personal
              information, or we may maintain it or associate it with personal
              information we collect in other ways or receive from third parties. It
              helps us to improve our Site and to deliver a better and more
              personalized service, including by enabling us to:</span
            >
          </p>
          <ul>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >Estimate our audience size and usage patterns.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >Store information about your preferences, allowing us to customize
                our Site according to your individual interests.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400">Speed up your searches.</span>
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >Recognize you when you return to our Site.</span
              >
            </li>
          </ul>
          <p>
            <span style="font-weight: 400"
              >The technologies we use for this automatic data collection may
              include:</span
            >
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>
              <strong
                ><strong>Cookies (or browser cookies).</strong
                ><span style="font-weight: 400">
                  A cookie is a small file placed on the hard drive of your computer.
                  You may refuse to accept browser cookies by activating the
                  appropriate setting on your browser. However, if you select this
                  setting you may be unable to access certain parts of our Site.
                  Unless you have adjusted your browser setting so that it will refuse
                  cookies, our system will issue cookies when you direct your browser
                  to our Site.&nbsp;</span
                ></strong
              >
            </li>
          </ul>
          <ul>
            <li>
              <strong>Flash Cookies.</strong
              ><span style="font-weight: 400">
                Certain features of our Site may use local stored objects (or Flash
                cookies) to collect and store information about your preferences and
                navigation to, from, and on our Site. Flash cookies are not managed by
                the same browser settings as are used for browser cookies. For
                information about managing your privacy and security settings for
                Flash cookies, see Choices About How We Use and Disclose Your
                Information.</span
              >
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li style="font-weight: 400">
              <strong>Web Beacons.</strong
              ><span style="font-weight: 400">
                Pages of our the Site and our e-mails may contain small electronic
                files known as web beacons (also referred to as clear gifs, pixel
                tags, and single-pixel gifs) that permit the Company, for example, to
                count users who have visited those pages or opened an email and for
                other related website statistics (for example, recording the
                popularity of certain website content and verifying system and server
                integrity).&nbsp;</span
              >
            </li>
          </ul>
          <p>
            <span style="font-weight: 400"
              >We do not collect personal information automatically, but we may tie
              this information to personal information about you that we collect from
              other sources or you provide to us.&nbsp;</span
            >
          </p>
          <p>
            <strong
              >Third-Party Use of Cookies and Other Tracking Technologies</strong
            >
          </p>
          <p>
            <span style="font-weight: 400"
              >Some content or applications, including advertisements, on the Site are
              served by third-parties, including advertisers, ad networks and servers,
              content providers, and application providers. These third parties may
              use cookies alone or in conjunction with web beacons or other tracking
              technologies to collect information about you when you use our website.
              The information they collect may be associated with your personal
              information or they may collect information, including personal
              information, about your online activities over time and across different
              websites and other online services. They may use this information to
              provide you with interest-based (behavioral) advertising or other
              targeted content.&nbsp;</span
            >
          </p>
          <p>
            <span style="font-weight: 400"
              >We do not control these third parties' tracking technologies or how
              they may be used. If you have any questions about an advertisement or
              other targeted content, you should contact the responsible provider
              directly. For information about how you can opt out of receiving
              targeted advertising from many providers, see Choices About How We Use
              and Disclose Your Information.</span
            >
          </p>
          <p><strong>How We Use Your Information</strong></p>
          <p>
            <span style="font-weight: 400"
              >We use information that we collect about you or that you provide to us,
              including any personal information:</span
            >
          </p>
          <ul>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >To present our Site and its contents to you.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >To provide you with information, products, or services from us or our
                other users.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >To market features, products, or special events using email or phone
                or send you marketing communications about third party products and
                services we think may be of interest to you.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >To Choose and deliver content and tailored advertising.&nbsp;</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >To fulfill any other purpose for which you provide it.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >To provide you with notices about your account.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >To carry out our obligations and enforce our rights arising from any
                contracts entered into between you and us, including for billing and
                collection.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >To notify you about changes to our Site or any products or services
                we offer or provide though it.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >To allow you to participate in interactive features on our
                Site.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >In any other way we may describe when you provide the
                information.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >For any other purpose with your consent.</span
              >
            </li>
          </ul>
          <p>
            <span style="font-weight: 400"
              >We may also use your information to contact you about our own and
              third-parties' goods and services that may be of interest to you. If you
              do not want us to use your information in this way, please adjust your
              user preferences in your account profile OR email us at
              <a href="mailto:support@avwaveinteractive.com"
                >support@avwaveinteractive.com</a
              >. For more information, see Choices About How We Use and Disclose Your
              Information.</span
            >
          </p>
          <p>
            <span style="font-weight: 400"
              >We may use the information we have collected from you to enable us to
              display advertisements to our advertisers' target audiences. Even though
              we do not disclose your personal information for these purposes without
              your consent, if you click on or otherwise interact with an
              advertisement, the advertiser may assume that you meet its target
              criteria.&nbsp;</span
            >
          </p>
          <p><strong>Disclosure of Your Information</strong></p>
          <p>
            <span style="font-weight: 400"
              >We may disclose aggregated information about our users, and information
              that does not identify any individual, without restriction.&nbsp;</span
            >
          </p>
          <p>
            <span style="font-weight: 400"
              >We may disclose personal information that we collect or you provide as
              described in this privacy policy:</span
            >
          </p>
          <ul>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >To our subsidiaries and affiliates, which may include our Licensees
                with whom you have contracted to receive the benefits of our
                Site.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400">To our other users.&nbsp;</span>
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >To contractors, service providers, and other third parties we use to
                support our business and who are bound by contractual obligations to
                keep personal information confidential and use it only for the
                purposes for which we disclose it to them.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >To a buyer or other successor in the event of a merger, divestiture,
                restructuring, reorganization, dissolution, or other sale or transfer
                of some or all of the Company's assets, whether as a going concern or
                as part of bankruptcy, liquidation, or similar proceeding, in which
                personal information held by the Company about our Site users is among
                the assets transferred.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >To other users and clients of the Site to market their products or
                services to you because you have consented to these disclosures by
                accepting the Terms and Conditions and using the Site. For more
                information, see Choices About How We Use and Disclose Your
                Information.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >To fulfill the purpose for which you provide it. For example, if you
                give us an email address to use the "email a friend" feature of our
                Site, we will transmit the contents of that email and your email
                address to the recipients.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >For any other purpose disclosed by us when you provide the
                information.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400">With your consent.</span>
            </li>
          </ul>
          <p>
            <span style="font-weight: 400"
              >We may also disclose your personal information:</span
            >
          </p>
          <ul>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >To comply with any court order, law, or legal process, including to
                respond to any government or regulatory request.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >To enforce or apply our terms and conditions <a [routerLink]="['/auth/terms']">Terms &amp; Conditions</a> and other agreements, including for billing and
                collection purposes.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >If we believe disclosure is necessary or appropriate to protect the
                rights, property, or safety of the Company, our customers, or others.
                This includes exchanging information with other companies and
                organizations for the purposes of fraud protection and credit risk
                reduction.</span
              >
            </li>
          </ul>
          <p>
            <strong>Choices About How We Use and Disclose Your Information</strong>
          </p>
          <p>
            <span style="font-weight: 400"
              >We strive to provide you with choices regarding the personal
              information you provide to us. We have created mechanisms to provide you
              with the following control over your information:&nbsp;</span
            >
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>
              <strong
                ><strong>Tracking Technologies and Advertising.</strong
                ><span style="font-weight: 400">
                  You can set your browser to refuse all or some browser cookies, or
                  to alert you when cookies are being sent. To learn how you can
                  manage your Flash cookie settings, visit the Flash player settings
                  page on Adobe's website. If you disable or refuse cookies, please
                  note that some or all of this Site may then be inaccessible or not
                  function properly.</span
                ></strong
              >
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li style="font-weight: 400">
              <strong>Promotional Offers from the Company.</strong
              ><span style="font-weight: 400">
                If you do not wish to have your contact information used by the
                Company to promote our own or third parties' products or services, you
                can opt-out by clicking &ldquo;unsubscribe&rdquo; in an email sent by
                us. If we have sent you a promotional email, you may send us a return
                email asking to be omitted from future email distributions. This opt
                out does not apply to information provided to the Company as a result
                of a product purchase, warranty registration, product service
                experience or other transactions.&nbsp;</span
              >
            </li>
          </ul>
          <p>
            <span style="font-weight: 400"
              >For all other purposes for which we use your information, you have
              consented to by agreeing to the Terms and Conditions and this Privacy
              Policy. If you do not wish for us to use your information as discussed
              in this Privacy Policy, you must not use the Site.</span
            >
          </p>
          <p>
            <span style="font-weight: 400"
              >We do not control third parties' collection or use of your information
              to serve interest-based advertising. However these third parties may
              provide you with ways to choose not to have your information collected
              or used in this way. You can opt out of receiving targeted ads from
              members of the Network Advertising Initiative ("</span
            ><strong>NAI</strong
            ><span style="font-weight: 400">") on the NAI's website.</span>
          </p>
          <p><strong>Accessing and Correcting Your Information</strong></p>
          <p>
            <span style="font-weight: 400"
              >You can review and change your personal information by logging into the
              Site and visiting your account profile page.</span
            >
          </p>
          <p>
            <span style="font-weight: 400"
              >You may also send us an email at
              <a href="mailto:support@avwaveinteractive.com"
                >support@avwaveinteractive.com</a
              >
              to request access to, correct or delete any personal information that
              you have provided to us. We cannot delete your personal information
              except by also deleting your user account. We may not accommodate a
              request to change information if we believe the change would violate any
              law or legal requirement or cause the information to be incorrect.</span
            >
          </p>
          <p><strong>Data Security</strong></p>
          <p>
            <span style="font-weight: 400"
              >We have implemented measures designed to secure your personal
              information from accidental loss and from unauthorized access, use,
              alteration, and disclosure. All information you provide to us is stored
              on our secure servers behind firewalls. Any payment transactions will be
              encrypted.</span
            >
          </p>
          <p>
            <span style="font-weight: 400"
              >The safety and security of your information also depends on you. Where
              we have given you (or where you have chosen) a password for access to
              certain parts of our Site, you are responsible for keeping this password
              confidential. You must not share your password with anyone.&nbsp;</span
            >
          </p>
          <p>
            <span style="font-weight: 400"
              >Unfortunately, the transmission of information via the internet is not
              completely secure. Although we do our best to protect your personal
              information, we cannot guarantee the security of your personal
              information transmitted to our Site. Any transmission of personal
              information is at your own risk. We are not responsible for
              circumvention of any privacy settings or security measures contained on
              the Site.</span
            >
          </p>
          <p><strong>Your Canada Data Protection Rights</strong></p>
          <p>
            <span style="font-weight: 400"
              >Residents of Canada may have the right to:</span
            >
          </p>
          <ul>
            <li style="font-weight: 400">
              <strong>Request that we not sell your personal information. </strong
              ><span style="font-weight: 400"
                >At this point in time, we do not sell our Users' personal
                information.&nbsp; If this changes in the future, we will let you
                know.&nbsp; If you have any questions about exercising this right,
                email us at&nbsp;</span
              ><a href="about:blank"
                ><span style="font-weight: 400"
                  ><a href="mailto:support@avwaveinteractive.com"
                    >support@avwaveinteractive.com</a
                  >.</span
                ></a
              >
            </li>
            <li style="font-weight: 400">
              <strong
                >Disclose to you whether we share your information with third parties
                for their direct marketing purposes.</strong
              ><span style="font-weight: 400"
                >&nbsp; If you would like to exercise this right, email us
                at&nbsp;</span
              ><a href="mailto:privacy@meredith.com"
                ><span style="font-weight: 400"
                  ><a href="mailto:support@avwaveinteractive.com"
                    >support@avwaveinteractive.com</a
                  >.</span
                ></a
              >
            </li>
            <li style="font-weight: 400">
              <strong
                >Review your personal information and correct or delete your
                information we have about you.&nbsp; </strong
              ><span style="font-weight: 400"
                >If you would like to exercise this right, please see </span
              ><span style="font-weight: 400"
                >Accessing and Correcting Your Information</span
              ><span style="font-weight: 400"
                >.&nbsp; Please note that if you request deletion of your personal
                information that is necessary to use the App, you may not be permitted
                to use the App.&nbsp;</span
              >
            </li>
          </ul>
          <p><strong>Lawful Basis for Processing Your Information</strong></p>
          <p>
            <span style="font-weight: 400"
              >We normally collect or use information from you or others only where we
              have your consent to do so, where we need the information to perform a
              contract with you, or where the processing is in our legitimate
              interests and not overridden by your data protection interests or
              fundamental rights and freedoms. In some cases, we may have a legal
              obligation to collect or retain personal information or may need the
              personal information to protect your vital interests or those of another
              person. For example, when we:</span
            >
          </p>
          <ul>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >Use information to create and manage an account, we need it in order
                to provide relevant services.</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >Use names and email addresses for email marketing purposes, we do so
                with your consent (which you can revoke at any time by not using this
                Site).</span
              >
            </li>
            <li style="font-weight: 400">
              <span style="font-weight: 400"
                >Gather usage data and analyze it to improve our Services, we do so
                based on our legitimate interest in safeguarding and improving our
                Services.</span
              >
            </li>
          </ul>
          <p>
            <span style="font-weight: 400"
              >If you have questions about or need further information concerning the
              lawful basis on which we collect and use your personal information,
              please contact us using the contact details provided at the bottom of
              this Privacy Policy.</span
            >
          </p>
          <p><strong>Changes to Our Privacy Policy</strong></p>
          <p>
            <span style="font-weight: 400"
              >It is our policy to post any changes we make to our privacy policy on
              this page. If we make material changes to how we treat our users'
              personal information, we will notify you by email to the email address
              specified in your account and through a notice on the Site home page.
              The date the privacy policy was last revised is identified at the top of
              the page. You are responsible for ensuring we have an up-to-date active
              and deliverable email address for you, and for periodically visiting our
              Site and this privacy policy to check for any changes.</span
            >
          </p>
          <p><strong>Contact Information</strong></p>
          <p>
            <span style="font-weight: 400"
              >To ask questions or comment about this privacy policy and our privacy
              practices, contact us at:
              <a href="mailto:support@avwaveinteractive.com"
                >support@avwaveinteractive.com</a
              >.&nbsp;</span
            >
          </p>
        </nb-card-body>
        <nb-card-footer><span>Last modified: August 19, 2020 </span>
        <section class="another-action" aria-label="Register">
                Return page <a class="text-link" routerLink="/auth/register">Register</a>
        </section>
        </nb-card-footer>
      </nb-card>
      
import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../../@core/utils';

@Component({
  selector: 'ngx-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {

  constructor(
    private titleService: TitleService,
  ) { }

  ngOnInit() {
    this.titleService.currentTitle = 'Terms and Conditions';
    this.titleService.currentSubTitle = null;
  }

}
